html,
body,
#root {
    min-height: 100%;
    height: 100%;
}

html {
    overflow-y: scroll;
}

#root {
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;
    overflow-y: auto;
}
